import { ProvinceConfigs } from '@src/api/config-api'
import {
  Constants,
  EBeneficiaryType,
  EGender,
  EGenderList,
  EHomeFeeType,
  EJobType,
  ELanguage,
  ELanguageList,
  ELoanPurpose,
  EOtherIncomeTypes,
} from '@src/types/constants'
import { OriginationTracking } from '@src/types/origination'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import * as yup from '@src/types/schemas/common/yup-extended'
import { StringSchema } from 'yup'

const nameRegex = /^([-A-ZÀ-Ü]|[a-zà-ü])+([-A-ZÀ-Ü]|[a-zà-ü]|['.\s])*$/i
const specialistRegex = /^([-A-ZÀ-Ü]|[a-zà-ü]|['.\s])*?$/
const civicNumberRegex = /^([0-9]+)([a-zA-Z])?$/

export const AddressSchema = yup.default.object({
  civicNumber: yup.default.string().IsNotEmpty().matches(civicNumberRegex).required().max(10),
  street: yup.default.string().IsNotEmpty().required().max(100),
  apartment: yup.default.string().max(50),
  suite: yup.default.string().max(10),
  postalCode: yup.default.string().required().nullable(false).IsValidCanadianPostalCode(),
  city: yup.default.string().IsNotEmpty().required().nullable(false).max(50),
  stateIso: yup.default.string().required().nullable(false),
  country: yup.default.string().default('Canada').oneOf(['Canada'], 'The country must be Canada'),
  months: yupExtInt.integer.positive().required(),
})

export type Address = yup.default.InferType<typeof AddressSchema>

export const ExpensesSchema = yup.default.object({
  homeFeeTypeId: yup.default.mixed<EHomeFeeType>().required().nullable(false).default(EHomeFeeType.Rent),
  totalMonthlyHomePayment: yupExtInt.integer
    .min(0)
    .default(0)
    .required()
    .when('homeFeeTypeId', {
      is: (val: EHomeFeeType) => {
        return val === EHomeFeeType.Rent || val === EHomeFeeType.OwnWithMortgage
      },
      then: yupExtInt.integer.positive().required(),
    }),
  rentMonthly: yupExtInt.integer,
  mortgageMonthly: yupExtInt.integer,
  housingMonthly: yupExtInt.integer
    .positive()
    .when('totalMonthlyHomePayment', {
      is: (val: number) => {
        return val > 0
      },
      then: yupExtInt.integer.required().max(yup.default.ref('totalMonthlyHomePayment')),
    })
    .when('homeFeeTypeId', {
      is: (val: EHomeFeeType) => {
        return val === EHomeFeeType.WithParents
      },
      then: yupExtInt.integer.min(0).default(0).required(),
    }),
})

export type Expenses = yup.default.InferType<typeof ExpensesSchema>

export const JobSchema = yup.default.object({
  jobType: yupExtInt.numberEnum<EJobType>().required(),
  employerName: yup.default
    .string()
    .max(100)
    .when('jobType', {
      is: EJobType.Unemployed,
      then: yup.default.string().nullable(true),
      otherwise: yup.default.string().IsNotEmpty().required(),
    }),
  jobTitle: yup.default.string().when('jobType', {
    is: EJobType.Unemployed,
    then: yup.default.string().nullable(true),
    otherwise: yup.default.string().IsNotEmpty().required().max(50),
  }),
  annualSalary: yupExtInt.integer.when('jobType', {
    is: EJobType.Unemployed,
    then: yupExtInt.integer.nullable(true),
    otherwise: yupExtInt.integer.required().min(1),
  }),
  employerPhone: yup.default.string().when('jobType', {
    is: EJobType.Unemployed,
    then: yup.default.string().nullable(true),
    otherwise: yup.default.string().IsValidCanadianPhone().required(),
  }),
  employerPhoneExt: yup.default.string().IsValidPhoneExtension().nullable(),
  months: yupExtInt.integer.when('jobType', {
    is: EJobType.Unemployed,
    then: yupExtInt.integer.nullable(true),
    otherwise: yupExtInt.integer.positive().required(),
  }),
  yearMonths: yupExtInt.integer.nullable(true),
  years: yupExtInt.integer.nullable(true),
})

export type ApplicantJob = yup.default.InferType<typeof JobSchema>

export const OtherIncomeSchema = yup.default.object({
  jobType: yupExtInt.integer.default(EJobType.Unemployed).required(),
  typeId: yupExtInt.integer.when('jobType', {
    is: EJobType.Unemployed,
    then: yupExtInt.integer.required(),
  }),
  annualAmount: yupExtInt.integer.when('typeId', {
    is: (value: number | null) => value !== null,
    then: yupExtInt.integer.required().min(1),
  }),
  description: yup.default.string().nullable(true).when('typeId', {
    is: EOtherIncomeTypes.other,
    then: yup.default.string().required(),
  }),
})

export type ApplicantOtherIncome = yup.default.InferType<typeof OtherIncomeSchema>

export const ApplicantSchema = (provincesConfigs: ProvinceConfigs = {}) =>
  yup.default.object({
    isCoapplicant: yup.default.boolean().default(false),
    genderId: yupExtInt.numberEnum<EGender>(EGenderList).required().default(EGender.Male),
    languageId: yupExtInt.numberEnum<ELanguage>(ELanguageList).required().default(0),
    birthDate: yup.default
      .string()
      .isValidDate()
      .validateAgeRequirement('common.errors.ageRequirement', provincesConfigs)
      .required()
      .nullable(false),
    firstName: yup.default.string().matches(nameRegex).required().nullable(false).max(50),
    middleName: yup.default.string().EmptyStringToNull().nullable().matches(nameRegex).max(50),
    lastName: yup.default.string().matches(nameRegex).required().nullable(false).max(50),
    sin: yup.default.string().nullable(false).default('').SinType(),
    relationWithApplicant: yup.default.string().when('isCoapplicant', (isCoapplicant, schema: StringSchema) => {
      if (!isCoapplicant) {
        return schema.notRequired().nullable(true).default(null)
      }
      return schema.required().nullable(false).default(null)
    }),
    cellPhone: yup.default.string().IsValidCanadianPhone().required(),
    homePhone: yup.default.string().IsValidCanadianPhone().nullable(true),
    email: yup.default.string().required().email().max(60),
    currentJobs: yup.default.array(JobSchema).default([JobSchema.getDefault()]),
    otherIncomes: yup.default.array(OtherIncomeSchema).default([OtherIncomeSchema.getDefault()]),
    currentAddress: AddressSchema.default(AddressSchema.getDefault()),
    spending: ExpensesSchema.default(ExpensesSchema.getDefault()),
    subscribeNewsletter: yup.default.boolean(),
  })

export type Applicant = yup.default.InferType<ReturnType<typeof ApplicantSchema>>

export const buildPrequalificationSchemaObject = (provincesConfigs?: ProvinceConfigs) => {
  const PrequalificationSchema = yup.default.object({
    merchantId: yup.default.string().required(),
    applicant: ApplicantSchema(provincesConfigs)
      .required()
      .nullable(false)
      .default(ApplicantSchema(provincesConfigs).getDefault()),
    requestedLoanAmount: yupExtInt.double.required().min(Constants.MinLoanAmount).max(Constants.MaxLoanAmount),
    loanPurposeId: yup.default.mixed<ELoanPurpose>().nullable(false).required().oneOf(Object.values(ELoanPurpose)),
    merchantName: yup.default.string().nullable(false).default(''),
    specialistName: yup.default.string().nullable(true).matches(specialistRegex, 'specialistNameErrorMessage'),
    merchantPaymentPlanId: yup.default.string().nullable(true),
    beneficiaryTypeId: yupExtInt.integer.default(1),
    otherBeneficiaryFirstName: yup.default
      .string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema: StringSchema) => {
        if (loanPurposeId === ELoanPurpose.Veterinary || loanPurposeId === ELoanPurpose.GoodsAndServices) {
          return schema.notRequired().nullable(true)
        }
        return schema.when('beneficiaryTypeId', {
          is: EBeneficiaryType.Other,
          then: yup.default.string().required(),
          otherwise: yup.default.string().notRequired().nullable(true),
        })
      }),
    otherBeneficiaryLastName: yup.default
      .string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema: StringSchema) => {
        if (loanPurposeId === ELoanPurpose.GoodsAndServices) {
          return schema.notRequired().nullable(true)
        }
        return schema.when('beneficiaryTypeId', {
          is: EBeneficiaryType.Other,
          then: yup.default.string().required(),
          otherwise: yup.default.string().notRequired().nullable(true),
        })
      }),
  })
  return PrequalificationSchema
}
export type Prequalification = yup.default.InferType<ReturnType<typeof buildPrequalificationSchemaObject>> & {
  origination: OriginationTracking | null
}

const PrequalificationSchema = buildPrequalificationSchemaObject()

export const LoanAmountInputSchema = PrequalificationSchema.pick(['requestedLoanAmount'])

export type LoanAmountInput = yup.default.InferType<typeof LoanAmountInputSchema>

export const LoanPurposeInputSchema = PrequalificationSchema.pick(['loanPurposeId'])

export const ServiceProviderSchema = PrequalificationSchema.pick([
  'merchantName',
  'merchantId',
  'specialistName',
  'loanPurposeId',
])

export type ServiceProviderInput = yup.default.InferType<typeof ServiceProviderSchema>

export type LoanPurposeInput = yup.default.InferType<typeof LoanPurposeInputSchema>

const applicantWithoutContactSchema = (provinceConfigs: ProvinceConfigs) =>
  ApplicantSchema(provinceConfigs).pick([
    'firstName',
    'middleName',
    'lastName',
    'genderId',
    'languageId',
    'birthDate',
    'sin',
    'relationWithApplicant',
  ])
const beneficiarySchema = PrequalificationSchema.pick([
  'beneficiaryTypeId',
  'otherBeneficiaryFirstName',
  'otherBeneficiaryLastName',
])

export const PersonalInformationInputSchema = (provinceConfigs: ProvinceConfigs) =>
  yup.default.object({
    applicant: applicantWithoutContactSchema(provinceConfigs),
    ...beneficiarySchema.fields,
  })

export type PersonalInformationInput = yup.default.InferType<ReturnType<typeof PersonalInformationInputSchema>>

export const ContactInformationInputSchema = ApplicantSchema().pick([
  'cellPhone',
  'homePhone',
  'email',
  'subscribeNewsletter',
])

export type ContactInformationInput = yup.default.InferType<typeof ContactInformationInputSchema>

export const HomeInformationInputSchema = (provinceConfigs: ProvinceConfigs) =>
  ApplicantSchema(provinceConfigs).pick(['currentAddress', 'spending', 'birthDate'])

export type HomeInformationInput = yup.default.InferType<ReturnType<typeof HomeInformationInputSchema>>

export const EmploymentInputSchema = ApplicantSchema().pick(['currentJobs', 'otherIncomes'])

export type EmploymentInput = yup.default.InferType<typeof EmploymentInputSchema>
