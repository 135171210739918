import { useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const FEATUREFLAG = 'feature-flags'
const LIST = 'list'

const fiveMinutes = 300000
const keysFactory = {
  allFeatureFlags: () => [{ scope: FEATUREFLAG, entity: LIST }] as const,
}

const getFeatureFlagsList = async () => {
  const response = await apiClient.get<FeatureFlag>('api/FeatureFlag')
  return response.data
}

export function useFeatureFlags(): [FeatureFlag | undefined, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.allFeatureFlags(),
    queryFn: getFeatureFlagsList,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data, isFetching]
}

export type FeatureFlag = {
  useIncludeInsuranceAllProvinces: boolean
}
