import { yupResolver } from '@hookform/resolvers/yup'
import { useGetPromotionalCreditAppPaymentAmount } from '@src/api/compute-api'
import { FilteredCreditApplication, useSubmitHardHit } from '@src/api/credit-api'
import {
  AcceptTermsCheckbox,
  AsyncActionButton,
  CancelAppButton,
  ExpandableSection,
  ManageCoappButton,
  SinInput,
} from '@src/components'
import {
  EditSinAndLoanAmount,
  buildEditSinAndLoanAmountSchema,
} from '@src/containers/ViewCreditApplication/components/EditPrequalificationSchema'
import {
  getMaxPaymentForRequestedAmount,
  getMinPaymentForRequestedAmount,
  getPrequalifiedAmount,
  mustAskApplicantSIN,
  mustAskCoapplicantSIN,
} from '@src/data/credit-application-selectors'
import { reportErrorToConsole } from '@src/services/error-logger'
import {
  FormatCurrencyRoundedUpToNoDecimals,
  FormatCurrencyToNearestLowerMultipleToNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import RemoveCoappButton from '@src/components/RemoveCoappButton'
import { useComputeMinMaxPaymentForLoanAmount } from '../../credit-hooks'
import MonthlyPaymentRange from './MonthlyPaymentRange'

type Props = {
  creditApp: FilteredCreditApplication
}

const ApprovedPrequalForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const initialPrequalifiedAmount = getPrequalifiedAmount(creditApp)
  const minPaymentForInitialApprovedAmount = getMinPaymentForRequestedAmount(creditApp)
  const maxPaymentForInitialApprovedAmount = getMaxPaymentForRequestedAmount(creditApp)
  const askApplicantSIN = mustAskApplicantSIN(creditApp)
  const askCoapplicantSIN = mustAskCoapplicantSIN(creditApp)
  const { requestedLoanAmount } = creditApp
  const maxLoanAmout = creditApp.prequalificationDecision.maxLoanAmount

  const shouldProposeCoapplicant = maxLoanAmout < requestedLoanAmount
  const hasCoapplicant = creditApp.coapplicant != null

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalSuccess)

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<EditSinAndLoanAmount>({
    mode: 'onBlur',
    defaultValues: {
      needsApplicantSin: askApplicantSIN,
      needsCoapplicantSin: askCoapplicantSIN === true,
      requestedLoanAmount: initialPrequalifiedAmount,
      id: creditApp.id,
    },
    resolver: yupResolver(buildEditSinAndLoanAmountSchema(creditApp.prequalificationDecision.maxLoanAmount)),
  })

  const updatedMonthlyPayment = useComputeMinMaxPaymentForLoanAmount(creditApp, requestedLoanAmount)

  const [submitHardHit, isSubmitting] = useSubmitHardHit()
  const innerSubmit = useCallback(
    (data: EditSinAndLoanAmount) => {
      submitHardHit(data).catch(reportErrorToConsole)
    },
    [submitHardHit],
  )

  const [promotionalPaymentAmounts] = useGetPromotionalCreditAppPaymentAmount(
    initialPrequalifiedAmount,
    creditApp.id,
    creditApp.merchantPaymentPlanId,
  )

  const mustShowUpdatedMonthlyPayment =
    creditApp.merchantPaymentPlan === null &&
    (Math.abs(maxPaymentForInitialApprovedAmount - updatedMonthlyPayment[1]) > 5 ||
      Math.abs(minPaymentForInitialApprovedAmount - updatedMonthlyPayment[0]) > 5)

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={handleSubmit(innerSubmit)}>
        <h1 className="h3" style={{ lineHeight: 1.5 }}>
          {creditApp.merchantPaymentPlan === null && (
            <Trans
              i18nKey="prequalification.prequalified"
              values={{
                requestedAmount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
                minMonthlyPayment: FormatCurrencyToNearestLowerMultipleToNoDecimals(
                  minPaymentForInitialApprovedAmount,
                  5,
                ),
                maxMonthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                  maxPaymentForInitialApprovedAmount,
                  5,
                ),
              }}
            />
          )}
          {creditApp.merchantPaymentPlan !== null && promotionalPaymentAmounts?.paymentForFrequency && (
            <Trans
              i18nKey="prequalification.prequalifiedVariableInterest"
              values={{
                requestedAmount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
                monthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                  promotionalPaymentAmounts.paymentForFrequency,
                  5,
                ),
              }}
            />
          )}
        </h1>
        <div className="paragraph">
          <div className="information">
            <Trans i18nKey="prequalification.loanDetails.mainDetails" />
          </div>
          <ExpandableSection toggleText="prequalification.loanDetails.learnMore">
            <p
              style={{
                fontStyle: 'italic',
                border: '1px solid #08abf0',
                borderRadius: '1.5rem',
                textAlign: 'center',
                padding: '1.5rem',
              }}
            >
              {t('prequalification.loanDetails.learnMoreDetails')}
            </p>
          </ExpandableSection>

          {shouldProposeCoapplicant && (
            <div style={{ marginTop: '32px', marginBottom: '32px' }}>
              <div className="strong">{t('prequalification.addCoapplicant.title')}</div>
              <div className="btn-group" style={{ marginTop: '16px' }}>
                {hasCoapplicant && <RemoveCoappButton creditAppId={creditApp.id} />}
                <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={hasCoapplicant} />
              </div>
            </div>
          )}

          {mustShowUpdatedMonthlyPayment && (
            <MonthlyPaymentRange min={updatedMonthlyPayment[0]} max={updatedMonthlyPayment[1]} />
          )}

          {askApplicantSIN && (
            <SinInput
              error={errors.applicantSin}
              id="applicantSin"
              reason="prequalification.applicantCreditLessThan2Years"
              {...register('applicantSin')}
            />
          )}

          {askCoapplicantSIN && (
            <SinInput
              error={errors.coapplicantSin}
              id="coapplicantSin"
              reason="prequalification.coapplicantCreditLessThan2Years"
              {...register('coapplicantSin')}
            />
          )}

          <div style={{ marginTop: '32px' }}>{t('prequalification.pullCreditReport')}</div>
          <AcceptTermsCheckbox
            id="acceptConditions"
            termId={`${String(t(hasCoapplicant ? 'prequalification.acceptConditionsWithCoapplicant' : 'prequalification.acceptConditions'))}²`}
            msgOnInvalid={t('common.acceptConditions')}
          />
        </div>

        <div className="btn-group mobile-reversed justify-end">
          <CancelAppButton creditAppId={creditApp.id} />
          <AsyncActionButton type="submit" isPending={isSubmitting} primary style={{ justifyContent: 'center' }}>
            <span>
              {shouldProposeCoapplicant
                ? t('common.continueWith', { amount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount) })
                : t('prequalification.continue')}
            </span>
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>
        </div>

        <div style={{ borderTop: '1px solid black', marginTop: '5rem' }}>
          <p style={{ marginTop: '2rem' }}>
            ¹{creditApp.merchantPaymentPlan === null && t('prequalification.priorToQualifyNotes.1')}
            {creditApp.merchantPaymentPlan !== null && promotionalPaymentAmounts?.paymentForFrequency && (
              <span>
                {t('prequalification.priorToQualifyNotes.1')}
                &nbsp;
                {creditApp.merchantPaymentPlan.borrowerFeeRate > 0 && (
                  <>
                    {t('prequalification.priorToQualifyNotes.fees', {
                      borrowerFee: creditApp.merchantPaymentPlan.borrowerFeeRate,
                    })}
                    &nbsp;
                  </>
                )}
                {t('prequalification.priorToQualifyNotes.estimate')}
              </span>
            )}
          </p>
          <p style={{ marginTop: '2rem' }}>
            ²
            {t(
              hasCoapplicant
                ? 'prequalification.priorToQualifyNotes.creditReportWithCoapplicant'
                : 'prequalification.priorToQualifyNotes.creditReport',
            )}
          </p>
        </div>
      </form>
    </main>
  )
}

export default ApprovedPrequalForm
