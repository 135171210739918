import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { DuplicateInfoDto, useGetActiveCreditAppLinkedToCellPhoneOrEmail } from '@src/api/credit-api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollableAlert } from '@src/components'
import {
  formatPhonesNumber,
  helpTip,
  phoneFormat,
  removePhoneFormat,
  validateObjectValue,
} from '../../../services/utils'
import { ContactInformationInput, ContactInformationInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  applicantData: ContactInformationInput
  onApplicantUpdated: (data: ContactInformationInput) => void
  prohibedPhone?: string
}

const ContactInformationForm = ({ applicantData, onApplicantUpdated, prohibedPhone }: Props) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<ContactInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(ContactInformationInputSchema),
    defaultValues: applicantData,
  })

  React.useEffect(() => {
    formatPhonesNumber()
    validateObjectValue(getValues(), trigger)
    helpTip()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [hasDuplicateLoanRequest, setHasDuplicateLoanRequest] = React.useState(false)

  const [activeCreditAppLinkedToCellPhoneOrEmail] = useGetActiveCreditAppLinkedToCellPhoneOrEmail()

  const handlePhoneOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const phone = removePhoneFormat(value)
    if (prohibedPhone && prohibedPhone === phone) {
      event.target.setCustomValidity(t('contact.coappSameMobileError'))
    }
    event.target.setCustomValidity('')
    setValue(id as 'cellPhone' | 'homePhone', phone, { shouldValidate: true })
  }

  const onSubmit = async (data: ContactInformationInput) => {
    const dto: DuplicateInfoDto = {
      email: data.email,
      cellPhone: data.cellPhone,
    }
    const isDuplicateRequest = await activeCreditAppLinkedToCellPhoneOrEmail(dto)
    if (!isDuplicateRequest) {
      onApplicantUpdated(data)
    } else {
      setHasDuplicateLoanRequest(true)
    }
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('contact.provideInformation')}</h3>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <ScrollableAlert
          showAlert={hasDuplicateLoanRequest}
          type="warn"
          message={t('contact.hasActiveLoanRequest')}
          link={t('contact.icebergPhone')}
          linkText={t('contact.icebergPhone')}
          linkType="tel"
        />
        <div className={`control-group mid  ${errors.cellPhone && 'error'}`}>
          <label htmlFor="mobile_phone"> {t('contact.mobilePhone')}</label>
          <div className="phone-input-wrapper">
            <input
              id="cellPhone"
              type="text"
              inputMode="numeric"
              placeholder="( ___ ) ___-____"
              className="phone-input"
              defaultValue={getValues('cellPhone')}
              onBlur={handlePhoneOnchange}
            />
            <span className="phone-input-decorator">+1</span>
          </div>
        </div>
        <div className={`control-group mid  ${errors.homePhone && 'error'}`}>
          <label htmlFor="otherphone">{t('contact.otherPhone')}</label>
          <div className="phone-input-wrapper">
            <input
              id="homePhone"
              type="text"
              inputMode="numeric"
              placeholder="( ___ ) ___-____"
              className="phone-input"
              defaultValue={phoneFormat(getValues('homePhone') as string)}
              onBlur={handlePhoneOnchange}
            />
            <span className="phone-input-decorator">+1</span>
          </div>
        </div>
        <div className={`control-group  ${errors.email && 'error'}`}>
          <label htmlFor="email">
            {t('contact.emailAddress')}
            <span className="help-tip">
              <p>{t('contact.emailAddressInfo')}</p>
            </span>
          </label>
          <input id="email" type="email" {...register('email')} maxLength={60} />
        </div>

        {/*  SUBSCRIBE NEWSLATTER AFTER MVP !IMPORTANT */}
        <div className="empty-space" />
        <div className="clarification">
          <p>
            {t('contact.newsletter')}
            <u>
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer">
                {t('contact.linkText')}
              </a>
            </u>
            {t('contact.newsletterEnd')}
          </p>
        </div>
        <div className="control-group">
          <input type="checkbox" id="newsletter" {...register('subscribeNewsletter')} />
          <label htmlFor="newsletter">{t('contact.subscribe')}</label>
        </div>
      </form>
    </section>
  )
}

export default ContactInformationForm
